
import client from '../client';
import qs from "qs";

//customer 리스트
export const getUploadColoringList = ({page,cate, search_filed, search}) => {
    const queryString = qs.stringify({
        page, cate,search_filed, search
    });

    return client.get(`/service/setting/coloring_list?${queryString}`)
}
