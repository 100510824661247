import {createAction, handleActions} from 'redux-actions';
import Loading from "../components/util/Loading";

const START_LOADING = 'loading/START_LOADING';
const FINISH_LOADING = 'loading/FINISH_LOADING'

export const startLoading = createAction(
    START_LOADING,
    requestType => requestType
)

export const finishLoading = createAction(
    FINISH_LOADING,
    requestType => requestType,
)

const initialState = {
    loading:false
};

const loading = handleActions(
    {
        [START_LOADING]:(state, action) => ({
            ...state,
            [action.payload]: true,
            loading:true
        }),
        [FINISH_LOADING]:(state, action)=> ({
            ...state,
            [action.payload]:false,
            loading:false
        })
    },
    initialState
)

export default  loading;