import {createAction, handleActions} from 'redux-actions';
import {takeLatest} from 'redux-saga/effects'
import * as coloringAPI from '../../lib/api/service/coloring'
import createRequestSaga, {createRequestActionTypes} from "../../lib/createRequestSaga";
import produce from "immer";

const INITIALIZE  = 'coloringSetting/INITIALIZE';
const [GET_COLORING_LIST, GET_COLORING_LIST_SUCCESS, GET_COLORING_LIST_FAILURE] = createRequestActionTypes('coloringSetting/GET_COLORING_LIST');

export const getColoringList = createAction(GET_COLORING_LIST, ({page, cate,search_filed, search}) => ({page, cate,search_filed, search}))

const getColoringListSaga = createRequestSaga(GET_COLORING_LIST, coloringAPI.getUploadColoringList);

export function* coloringSettingSaga(){
    yield takeLatest(GET_COLORING_LIST, getColoringListSaga);
}

const initialState = {
    coloring:{
        list:null,
        page:null,
        error:null
    },
}
const coloringSetting = handleActions(
    {
        [GET_COLORING_LIST_SUCCESS]:(state, {payload: data}) =>
            produce(state, draft => {
                draft.coloring.list = data.list
                draft.coloring.page = data.page
                draft.coloring.error = data.null
            }),
        [GET_COLORING_LIST_FAILURE]:(state, {payload: error}) =>
            produce(state, draft => {
                draft.coloring = initialState.coloring
                draft.coloring.error = error.response.data
            }),
    },
    initialState
)




export default coloringSetting;