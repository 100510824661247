import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';
import {takeLatest} from 'redux-saga/effects';
import createRequestSaga, {createRequestActionTypes} from "../../lib/createRequestSaga";
import * as dataProfileAPI from '../../lib/api/service/data_profile';

const [DATA_PROFILE,DATA_PROFILE_SUCCESS, DATA_PROFILE_FAILURE] = createRequestActionTypes('data_profile/DATA_PROFILE')
const INITIALIZE  = 'data_profile/INITIALIZE';

export const dataProfile = createAction(DATA_PROFILE)

export const initializeProfile = createAction(INITIALIZE);

const dataProfileSage = createRequestSaga(DATA_PROFILE, dataProfileAPI.dataProfile);

export function* dataProfileSaga(){
    yield takeLatest(DATA_PROFILE, dataProfileSage);
}

const initialState = {
    dataProfile:{
        result:null,
        count:null
    }
};

const data_profile = handleActions(
    {
        [DATA_PROFILE_SUCCESS]:(state, {payload: data}) =>
            produce(state, draft => {
                draft.dataProfile.result = true
                draft.dataProfile.count = data
            }),
        [DATA_PROFILE_FAILURE]:(state, {payload: error}) =>
            produce(state, draft => {
                draft.dataProfile.result = false
                draft.dataProfile.count = 0
            }),
        [INITIALIZE]: (state, {payload: form}) => ({
            ...initialState
        }),

    },
    initialState
);
export default data_profile;
