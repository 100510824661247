import {createAction, handleActions} from 'redux-actions';
import produce from 'immer';
import {takeLatest} from 'redux-saga/effects';
import createRequestSaga, {createRequestActionTypes} from "../../lib/createRequestSaga";
import * as imageAPI from '../../lib/api/service/message_image';

const [GET_IMAGE_LIST, GET_IMAGE_LIST_SUCCESS, GET_IMAGE_LIST_FAILURE] = createRequestActionTypes('image/GET_IMAGE_LIST');
const [CREATE_IMAGE, CREATE_IMAGE_SUCCESS, CREATE_IMAGE_FAILURE] = createRequestActionTypes('image/CREATE_IMAGE');


const INITIALIZE = 'image/INITIALIZE';

export const getImageList = createAction(GET_IMAGE_LIST, ({page}) => ({page}));
export const createImage = createAction(CREATE_IMAGE, ({subject, image}) => ({subject, image}));


// export const initialize = createAction(INITIALIZE, ());
export const initialize = createAction(INITIALIZE, from => from);

const getImageListSaga = createRequestSaga(GET_IMAGE_LIST, imageAPI.getImageList);
const createImageSaga = createRequestSaga(CREATE_IMAGE, imageAPI.createImage);

export function* image_uploadSaga() {
    yield takeLatest(GET_IMAGE_LIST, getImageListSaga);
    yield takeLatest(CREATE_IMAGE, createImageSaga);
}

const initialState = {
    image_list: {
        list: null,
        page: null,
    },
    create_image: null,
    error: null,
};

const message_image = handleActions(
    {
        [GET_IMAGE_LIST_SUCCESS]: (state, {payload: data}) =>
            produce(state, draft => {
                draft.image_list = data;
            }),
        [GET_IMAGE_LIST_FAILURE]: (state, {payload: error}) =>
            produce(state, draft => {
                draft.image_list = initialState.image_list
            }),
        [CREATE_IMAGE_SUCCESS]: (state, {payload: data}) =>
            produce(state, draft => {
                draft.create_image = data;
            }),
        [CREATE_IMAGE_FAILURE]: (state, {payload: error}) =>
            produce(state, draft => {
                draft.create_image = initialState.create_image
            }),
        [INITIALIZE]: (state, {payload: form}) => ({
            ...initialState
        }),

    },
    initialState
    )
;
export default message_image;