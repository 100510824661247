import client from '../client';
import qs from "qs";

export const getImageList = ({page}) => {
    const queryString = qs.stringify({
    page
    });

    console.log('queryString :: ' + queryString)
    return client.get(`/service/message/imageList?${queryString}`)
};

export const createImage = ({subject, image}) => {

    let formData = new FormData();
    formData.append("subject", subject);
    formData.append("image", image);

    const config = {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    };

    return client.post(`/service/message/imageUpload`, formData, config)
};

